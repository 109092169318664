import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import {
    CalendarClock,
    ChevronDown,
    ChevronUp,
    Circle,
    Diamond,
    Dot,
    Download,
    FileBox,
    Filter,
    ListRestart,
    LoaderCircle,
    LucideIcon,
    Map as MapIcon,
    Minus,
    Paintbrush,
    Play,
    Plus,
    Share2,
    SlidersHorizontal,
    Square,
    SwitchCamera,
} from "lucide-react";
import { Polygon } from "ol/geom";
import "ol/ol.css";
import { useCallback, useEffect, useRef, useState } from "react";
import lumidbLogo from "../assets/lumidb_logo_black.svg";
import { BASEMAP_SOURCE, LumiMap, polygonFromExtent, SelectedFiles } from "./map";
import { PointChunk } from "./point-chunk";
import { COLOR_MODE, COLOR_MODE_NAMES, CustomPointMaterial } from "./point-material";
import "./style.css";
import { fetchMetadata, FileMetadataItem, getRampedColor, humanArea, humanDuration, humanSize } from "./utils";
import { FetchInfo, Viewer } from "./viewer";

const lasClasses = {
    0: "Unassigned",
    1: "Unclassified",
    2: "Ground",
    3: "Low Vegetation",
    4: "Medium Vegetation",
    5: "High Vegetation",
    6: "Building",
    7: "Noise",
    8: "Keypoint",
    9: "Water",
    12: "Overlap",
    17: "Bridge Deck",
} as const;

const YEARS = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];

function Info(props: { heading: string; children: React.ReactNode }) {
    return (
        <div className="mt-1 flex gap-2 text-sm">
            <div className="w-12 font-bold text-gray-800">{props.heading}: </div>
            <div>{props.children}</div>
        </div>
    );
}

const VIEWER_STATE_VERSION = 5;

const defaultViewerState = {
    baseMap: "osm" as keyof typeof BASEMAP_SOURCE,
    colorMode: "rgb" as keyof typeof COLOR_MODE,
    yearRange: [YEARS.at(0), YEARS.at(-1)] as [number, number],
    maxPoints: 2_000_000,
    version: VIEWER_STATE_VERSION,
    // Senaatintori
    queryPolygon: polygonFromExtent([2777453, 8437429, 2777844, 8437919]).getCoordinates(),
    classificationMask: 0xffff_ffff,
    camera: null as { position: number[]; rotate: boolean } | null,
    toggled: {
        date: false,
        pointLimit: false,
        class: false,
    },
};

type ViewerState = typeof defaultViewerState;

let saveStateHandle = 0;
function saveViewerState(change: (state: ViewerState) => void) {
    change(state);

    // round camera coords
    if (state.camera) {
        state.camera.position = state.camera.position.map((n) => Number(n.toFixed(0)));
    }

    const asString = JSON.stringify(state);
    localStorage.setItem("state", asString);

    if (saveStateHandle) {
        clearTimeout(saveStateHandle);
        saveStateHandle = 0;
    }
    saveStateHandle = setTimeout(() => {
        const pars = new URLSearchParams(window.location.search);
        pars.set("state", btoa(asString));
        window.history.replaceState({}, "", "?" + pars.toString());
    }, 200);
}

function loadViewerState(): ViewerState | null {
    const q = new URLSearchParams(window.location.search);
    let s = "";
    if (q.get("state")) {
        s = atob(q.get("state") ?? "");
    } else {
        s = localStorage.getItem("state") ?? "";
        if (s) {
            console.log("load viewer state from localStorage");
        }
    }

    if (!s) {
        console.log("no viewer state stored");
        return null;
    }

    try {
        const newState: ViewerState = JSON.parse(s ?? "");
        if (newState.version === VIEWER_STATE_VERSION) {
            console.log("state", newState);
            return newState;
        } else {
            console.error("unsupported viewer state version", newState);
            resetViewerState();
            return null;
        }
    } catch (e) {
        console.error("failed to parse viewer state", e);
    }
    return null;
}

function resetViewerState() {
    localStorage.removeItem("state");
    // drop query param from URL and reload
    window.history.replaceState({}, "", window.location.origin);
    window.location.reload();
}

const state = loadViewerState() ?? defaultViewerState;

export function Collapsible(props: {
    toggled: boolean;
    text: string;
    icon: LucideIcon;
    children: React.ReactNode;
    extra?: React.ReactNode;
    isWarning?: () => boolean;
    buttonClicked: () => void;
}) {
    const button = (
        <Button
            size="sm"
            variant={props.toggled ? "default" : props.isWarning && props.isWarning() ? "destructive" : "outline"}
            onClick={props.buttonClicked}
            className="self-start"
        >
            <props.icon className="h-4" /> {props.text}
        </Button>
    );
    if (!props.toggled) {
        return button;
    }

    return (
        <div className="pointer-events-auto w-fit rounded-md bg-white/40 p-2 text-sm backdrop-blur-sm">
            <div className="mb-2 flex items-center gap-4">
                {button}
                {props.extra}
            </div>
            {props.children}
        </div>
    );
}

const viewer = new Viewer();
const map = new LumiMap();

function ViewerContainer() {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current) return;

        containerRef.current.appendChild(viewer.renderer.domElement);

        return () => {
            containerRef.current?.removeChild(viewer.renderer.domElement);
        };
    }, []);

    return (
        <div
            id="viewer-container"
            ref={containerRef}
            className="h-full w-full"
            style={{ background: "radial-gradient(rgb(255, 255, 255), rgba(52, 45, 134, 0.15))" }}
        ></div>
    );
}

function SelectTable(props: { initial: string; onValueChanged: (value: string) => void }) {
    const [tables, setTables] = useState<string[]>([]);

    useEffect(() => {
        fetch("/api/tables")
            .then((r) => r.json())
            .then((tables) => {
                setTables(tables.tables);
            });
    }, []);

    return (
        <Select value={props.initial} onValueChange={props.onValueChanged}>
            <SelectTrigger className="w-32 bg-white">
                <SelectValue />
            </SelectTrigger>
            <SelectContent>
                {tables.map((table) => (
                    <SelectItem key={table} value={table}>
                        {table}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

function getCurrentTable() {
    const pars = new URLSearchParams(window.location.search);
    const t = pars.get("table");
    if (t) {
        return t;
    } else {
        return "helsinki";
    }
}

function FileInfoRow(props: { info: FileMetadataItem; idx: number; viewer: Viewer | null }) {
    const colorText = "rgb(" + getRampedColor(props.idx).join(", ") + ")";

    return (
        <div
            className="items-top my-1 flex gap-1"
            key={props.idx}
            onPointerEnter={() => {
                props.viewer?.updateHighlightedBoundaries([props.info.original_filename]);
            }}
            onPointerLeave={() => {
                props.viewer?.updateHighlightedBoundaries([]);
            }}
        >
            <div style={{ backgroundColor: colorText }} className="h-5 w-5 rounded-sm"></div>
            <div>
                <a className="font-bold text-gray-700" href={"/api/" + props.info.original_filename}>
                    {props.info.original_filename.split("/").slice(1).join("/")}
                </a>
            </div>
            <div>{humanSize(props.info.file_size)}</div>
        </div>
    );
}

function FilesByLicense(props: {
    responseInfo: FetchInfo | null;
    fileMetadata: Map<number, FileMetadataItem>;
    viewer: Viewer | null;
}) {
    const noLicense: [number, FileMetadataItem][] = [];
    const byLicense: Record<string, [number, FileMetadataItem][]> = {};

    for (const idx of props.responseInfo?.seenIndices ?? []) {
        const info = props.fileMetadata.get(idx);
        if (!info) {
            continue;
        }

        const license = info.license;

        if (license) {
            if (byLicense[license]) {
                byLicense[license].push([idx, info]);
            } else {
                byLicense[license] = [[idx, info]];
            }
        } else {
            noLicense.push([idx, info]);
        }
    }

    return (
        <div className="divide-y">
            {noLicense.map(([idx, f]) => (
                <FileInfoRow key={f.original_filename} info={f} idx={idx} viewer={props.viewer} />
            ))}

            {Object.entries(byLicense).map(([license, files]) => (
                <div key={license}>
                    <div className="my-1 text-xs font-bold text-gray-800">{license}:</div>
                    {files.map(([idx, f]) => (
                        <FileInfoRow key={f.original_filename} info={f} idx={idx} viewer={props.viewer} />
                    ))}
                </div>
            ))}
        </div>
    );
}

export function App() {
    const mapElement = useRef(null as null | HTMLDivElement);

    const initialized = useRef(false);

    const [tableName, setTableName] = useState(getCurrentTable());

    const tableNameRef = useRef(tableName);

    const [modifyPointLimit, setModifyPointLimit] = useState(state.toggled.pointLimit);
    const [pointLimit, setPointLimit] = useState(state.maxPoints);
    const [responseInfo, setResponseInfo] = useState(null as null | FetchInfo);

    const [selectedFiles, setSelectedFiles] = useState([] as SelectedFiles);

    const [activeDrawing, setActiveDrawing] = useState(null as null | "box" | "polygon");

    const [filterClassification, setFilterClassification] = useState(state.toggled.class);
    const [classificationMask, setClassificationMask] = useState(state.classificationMask);

    const [colorMode, setColorMode] = useState(state.colorMode);

    const [filterTimeRange, setFilterTimeRange] = useState(state.toggled.date);
    const [timeRange, setTimeRange] = useState(state.yearRange);

    const [loadCount, setLoadCount] = useState(0);

    const [fileMetadata, setFileMetadata] = useState(new Map<number, FileMetadataItem>());
    const [uiLayout, setUiLayout] = useState("horizontal" as "horizontal" | "vertical");

    const [yearsWithData, setYearsWithData] = useState(new Set() as Set<number>);
    const [metadataLoaded, setMetadataLoaded] = useState<string | null>(null);

    const [shareLinkText, setShareLinkText] = useState("Share");
    const [filesUsedCollapsed, setFilesUsedCollapsed] = useState(true);

    function onShareLink() {
        const url = window.location.origin + "?state=" + btoa(JSON.stringify(state));
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setShareLinkText(() => "Copied!");
                setTimeout(() => {
                    setShareLinkText("Share");
                }, 1000);
                console.log("URL copied to clipboard!", url);
            })
            .catch((err) => {
                console.error("Error copying to clipboard: ", err);
            });
    }

    const getMetaFilter = useCallback(() => {
        if (fileMetadata.size > 0 && timeRange) {
            return Array.from(fileMetadata.entries())
                .filter(([_idx, v]) => {
                    return v.year >= timeRange[0] && v.year < timeRange[1];
                })
                .map(([idx, _v]) => idx);
        } else {
            return null;
        }
    }, [fileMetadata, timeRange]);

    // run then initialization once after first render
    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        const viewerContainer = document.getElementById("viewer");
        if (!viewerContainer) {
            throw new Error("#viewer not found");
        }

        function resizeUI() {
            const root = document.getElementById("root");
            if (!root) {
                throw new Error("#root element found");
            }

            if (root.clientHeight > root.clientWidth) {
                setUiLayout("vertical");
            } else {
                setUiLayout("horizontal");
            }

            if (viewerContainer) {
                viewer.setSizeDebounced(viewerContainer.clientWidth, viewerContainer.clientHeight);
            }
        }

        resizeUI();

        window.addEventListener("resize", () => {
            resizeUI();
        });

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === viewerContainer) {
                    resizeUI();
                }
            }
        });

        resizeObserver.observe(viewerContainer);

        viewer.requestRender();

        viewer.addEventListener("fetchCompleted", (info) => {
            setResponseInfo(info);
            map.updateCurrentSelection(info.queryPolygon);
            saveViewerState((state) => (state.queryPolygon = info.queryPolygon));
        });

        if (state.camera) {
            viewer.setCamera(state.camera.position, state.camera.rotate);
        }

        map.updateCurrentSelection(state.queryPolygon);

        map.onPolygonUpdated = (poly) => {
            if (poly) {
                saveViewerState((state) => (state.queryPolygon = poly));
                viewer.fetchPoints({
                    tableName: tableNameRef.current,
                    filter: getMetaFilter(),
                    pointLimit: state.maxPoints,
                    queryPolygon: poly,
                    why: "onPolygonUpdated",
                });
            } else {
                setActiveDrawing(null);
            }
        };

        map.onSelectedFilesChanged = (type, files) => {
            setSelectedFiles(files);

            if (type === "hover") {
                viewer.updateHighlightedBoundaries(files.map((s) => s.original_filename));
            } else {
                viewer.updateHighlightedBoundaries([]);
            }
        };

        map.setBaseMap(state.baseMap);

        viewer.onLoadCountUpdated = (count) => {
            setLoadCount(count);
        };

        viewer.onCameraPositionChanged = ({ camera, offset }) => {
            map.updateCameraLocation(
                camera.position[0] + offset[0],
                camera.position[1] + offset[1],
                camera.compassAngleRad,
            );
            saveViewerState((state) => (state.camera = { position: camera.position, rotate: camera.rotate }));
        };

        viewer.setColorMode(state.colorMode);

        window.addEventListener("keydown", (e) => {
            // TODO: the shortcut keys should be visible somewhere in the UI
            if (e.key === "b") {
                setActiveDrawing("box");
            }

            if (e.key === "p") {
                setActiveDrawing("polygon");
            }

            if (e.key === "1") {
                setColorMode("rgb");
            }

            if (e.key === "2") {
                setColorMode("classification");
            }

            if (e.key === "3") {
                setColorMode("intensity");
            }

            if (e.key === "4") {
                setColorMode("metaIndex");
            }

            if (e.key === "5") {
                setColorMode("date");
            }

            if (e.key === "Escape") {
                setActiveDrawing(null);
            }
        });
    }, []);

    useEffect(() => {
        if (mapElement.current) {
            map.setContainer(mapElement.current);
        }
    }, [mapElement]);

    useEffect(() => {
        const handle = setTimeout(() => {
            setMetadataLoaded(null);
            tableNameRef.current = tableName;
            const pars = new URLSearchParams(window.location.search);
            pars.set("table", tableName);
            window.history.replaceState({}, "", "?" + pars.toString());
            fetchMetadata(tableName)
                .then(({ meta, projection }) => {
                    setFileMetadata(meta);

                    const years = new Set<number>();
                    let biggest_index = 0;

                    for (const [idx, m] of meta.entries()) {
                        if (m.year) {
                            years.add(m.year);
                        }
                        biggest_index = Math.max(biggest_index, idx);
                    }

                    setYearsWithData(years);
                    map.updateVisibleBounds(meta, projection);
                    viewer.addBoundingMeshes(meta);
                    viewer.updateBoundsCenter();

                    if (biggest_index >= 2048) {
                        // TODO: expand the texture size by making it use multiple rows
                        throw new Error("too many files for our tiny index-to-year lookup table texture");
                    }

                    const lookup: number[] = Array.from({ length: biggest_index });
                    for (const [idx, m] of meta.entries()) {
                        lookup[idx] = m.year ?? 0;
                    }
                    CustomPointMaterial.updateYearLookup(lookup);
                })
                .then(() => {
                    // TODO: remove once the backend can return tight bounds in the meta query
                    map.loadGeoJSON(`/api/tables/${tableName}/file_bounds`, "EPSG:3879").then((features) => {
                        const hasMeta = features.map((f) => f.get("meta_index") ?? 0);
                        setYearsWithData((old) => new Set(old).union(new Set(hasMeta)));
                    });
                })
                .then(() => {
                    setMetadataLoaded(tableName);
                });
        }, 100);
        return () => {
            clearTimeout(handle);
        };
    }, [tableName]);

    useEffect(() => {
        saveViewerState((state) => (state.maxPoints = pointLimit));
        const timer = viewer.fetchPoints({
            tableName: tableNameRef.current,
            filter: getMetaFilter(),
            pointLimit: pointLimit,
            queryPolygon: state.queryPolygon,
            why: "pointLimit",
        });
        () => clearTimeout(timer);
    }, [pointLimit]);

    useEffect(() => {
        saveViewerState((state) => (state.classificationMask = classificationMask));
        viewer.setClassificationMask(classificationMask);
    }, [classificationMask]);

    useEffect(() => {
        saveViewerState((state) => (state.colorMode = colorMode));
        viewer.setColorMode(colorMode);
        map.setColorMode(colorMode);
    }, [colorMode]);

    useEffect(() => {
        map.updateActiveYearRange(timeRange);
        saveViewerState((state) => (state.yearRange = timeRange));
        const timer = viewer.fetchPoints({
            tableName: tableNameRef.current,
            filter: getMetaFilter(),
            pointLimit: state.maxPoints,
            queryPolygon: state.queryPolygon,
            why: "timeRange",
        });
        () => clearTimeout(timer);
    }, [timeRange]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.class = filterClassification));
    }, [filterClassification]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.date = filterTimeRange));
    }, [filterTimeRange]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.pointLimit = modifyPointLimit));
    }, [modifyPointLimit]);

    useEffect(() => {
        if (activeDrawing === "box") {
            map.drawBox();
        } else if (activeDrawing === "polygon") {
            map.drawPolygon();
        } else if (activeDrawing === null) {
            map.cancelDraw();
        }
    }, [activeDrawing]);

    return (
        <div className="flex h-full select-none">
            <ResizablePanelGroup direction={uiLayout}>
                <ResizablePanel defaultSize={40}>
                    {/* MAP */}
                    <div id="map" ref={mapElement} className="relative h-full w-full">
                        <div className="pointer-events-none absolute left-2 top-2 z-30 flex w-[256px] flex-shrink-0 flex-col gap-2">
                            <div className="mx-2 my-4 w-44 opacity-80">
                                <img src={lumidbLogo} />
                            </div>

                            <div className="mb-4 flex flex-col items-start gap-1">
                                <div className="pointer-events-auto mb-4">
                                    <SelectTable initial={tableName} onValueChanged={(t) => setTableName(t)} />
                                </div>
                                <Button
                                    size="sm"
                                    variant={activeDrawing === "box" ? "default" : "outline"}
                                    onClick={() => {
                                        if (activeDrawing === "box") {
                                            setActiveDrawing(null);
                                        } else {
                                            setActiveDrawing("box");
                                        }
                                    }}
                                >
                                    <Square className="h-4" />
                                    Box
                                </Button>
                                <Button
                                    size="sm"
                                    variant={activeDrawing === "polygon" ? "default" : "outline"}
                                    onClick={() => {
                                        if (activeDrawing === "polygon") {
                                            setActiveDrawing(null);
                                        } else {
                                            setActiveDrawing("polygon");
                                        }
                                    }}
                                >
                                    <Diamond className="h-4" />
                                    Polygon
                                </Button>
                            </div>

                            <Collapsible
                                buttonClicked={() => setFilterTimeRange((v) => !v)}
                                icon={CalendarClock}
                                text="Date"
                                toggled={filterTimeRange}
                                isWarning={() =>
                                    metadataLoaded == tableName &&
                                    (yearsWithData.size === 0 ||
                                        Array.from(yearsWithData.values()).every(
                                            (v) => v < timeRange[0] || v >= timeRange[1],
                                        ))
                                }
                            >
                                <div className="mb-4 mt-4 flex w-64 flex-col gap-2">
                                    <div
                                        className="mt-2 grid select-none px-1.5 text-sm"
                                        style={{
                                            gridTemplateColumns: "repeat(auto-fit, minmax(1px, max-content))",
                                        }}
                                    >
                                        {YEARS.slice(0, -1).map((year) => (
                                            <div
                                                key={year}
                                                className={
                                                    "-rotate-90 " +
                                                    (year >= timeRange[0] && year < timeRange[1] ? "" : "text-gray-300")
                                                }
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="grid select-none gap-1 px-2 text-sm"
                                        style={{
                                            gridTemplateColumns: "repeat(auto-fit, minmax(1px, max-content))",
                                        }}
                                    >
                                        {YEARS.slice(0, -1).map((year) => {
                                            const colorText = "rgb(" + getRampedColor(year).join(", ") + ")";
                                            const hasData = yearsWithData.has(year);
                                            const inRange = year >= timeRange[0] && year < timeRange[1];
                                            return (
                                                <div
                                                    key={year}
                                                    style={{
                                                        backgroundColor: hasData ? colorText : "rgba(100,100,100,0.2)",
                                                    }}
                                                    className={
                                                        "flex h-5 w-5 rounded-sm " +
                                                        (inRange && hasData ? "border border-black" : "")
                                                    }
                                                ></div>
                                            );
                                        })}
                                    </div>
                                    <Slider
                                        className="w-full"
                                        min={YEARS.at(0)}
                                        max={YEARS.at(-1)}
                                        value={timeRange}
                                        minStepsBetweenThumbs={1}
                                        onValueChange={(value) => {
                                            setTimeRange(value as [number, number]);
                                        }}
                                    />
                                </div>
                            </Collapsible>

                            <Collapsible
                                toggled={modifyPointLimit}
                                icon={SlidersHorizontal}
                                text="Point Limit"
                                buttonClicked={() => setModifyPointLimit((v) => !v)}
                                extra={<div>{(pointLimit / 1_000_000).toFixed(3)} M</div>}
                            >
                                <Slider
                                    className="mt-2 w-full py-2"
                                    min={10_000}
                                    max={8_000_000}
                                    step={10_000}
                                    value={[pointLimit]}
                                    onValueChange={(value) => {
                                        setPointLimit(value[0]);
                                    }}
                                />
                            </Collapsible>

                            <Collapsible
                                text="Class"
                                icon={Filter}
                                toggled={filterClassification}
                                buttonClicked={() => setFilterClassification((v) => !v)}
                                isWarning={() => classificationMask !== 0xffff_ffff}
                                extra={
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        onClick={() => {
                                            if (classificationMask === 0xffff_ffff) {
                                                setClassificationMask(0);
                                            } else {
                                                setClassificationMask(0xffff_ffff);
                                            }
                                        }}
                                    >
                                        Toggle All
                                    </Button>
                                }
                            >
                                <div className="flex flex-col gap-2">
                                    {Object.entries(lasClasses).map(([k, v]) => (
                                        <div className="flex items-center gap-2" key={k}>
                                            <Checkbox
                                                id={"toggle-class-" + k}
                                                checked={(classificationMask & (1 << Number(k))) > 0}
                                                onCheckedChange={(checked) => {
                                                    setClassificationMask((prev) => {
                                                        // use >>> 0 to force the numbers to be unsigned
                                                        const next = checked
                                                            ? (prev | (1 << Number(k))) >>> 0
                                                            : (prev & ~(1 << Number(k))) >>> 0;
                                                        return next;
                                                    });
                                                }}
                                            />
                                            <Label htmlFor={"toggle-class-" + k}>
                                                {k}: {v}
                                            </Label>
                                        </div>
                                    ))}
                                </div>
                            </Collapsible>
                        </div>

                        <div className="absolute left-2 top-2 z-10 flex items-start gap-1"></div>

                        <div className="absolute right-2 top-2 z-10 flex gap-1 text-sm">
                            <Button variant="outline" size="sm" onClick={() => map.zoom(1)}>
                                <Plus className="h-4" />
                            </Button>

                            <Button variant="outline" size="sm" onClick={() => map.zoom(-1)}>
                                <Minus className="h-4" />
                            </Button>

                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" size="sm" title="Change basemap">
                                        <MapIcon className="h-4" /> Map
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("osm")}>
                                        OpenStreetMap
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("googleRoads")}>
                                        Google
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("kapsiOrto")}>
                                        MML Ortho
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("kapsiTausta")}>
                                        MML Basemap
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>

                        <div className="absolute bottom-2 left-2 z-10 rounded-md bg-white/40 p-2 text-xs backdrop-blur-sm">
                            {selectedFiles.map((f) => (
                                <div className="items-top flex gap-1" key={f.original_filename}>
                                    <div>
                                        <FileBox className="h-5" />
                                    </div>
                                    <div>
                                        <a className="font-bold text-gray-700" href={"/api/" + f.original_filename}>
                                            {f.original_filename.split("/").slice(1).join("/")}
                                        </a>
                                    </div>
                                    <div>{humanSize(f.file_size)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ResizablePanel>
                <ResizableHandle withHandle></ResizableHandle>
                <ResizablePanel defaultSize={60}>
                    {/* VIEWER */}
                    <div id="viewer" className="relative h-full w-full">
                        <div className="absolute left-2 top-2">
                            <div className="flex items-center gap-1"></div>
                        </div>

                        <div className="absolute left-1/2 top-2 flex -translate-x-1/2 gap-1">
                            {loadCount > 0 && (
                                <Button size="sm" variant="outline">
                                    <LoaderCircle className="animate-spin"></LoaderCircle>
                                    <div className="ml-2">Loading</div>
                                </Button>
                            )}
                        </div>

                        <div className="absolute right-2 top-2 flex gap-1">
                            <DropdownMenu modal={false}>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" size="sm" title="Change color mode">
                                        <Paintbrush className="h-4" />
                                        Color: {COLOR_MODE_NAMES[colorMode]}
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="ml-2 w-56">
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem onClick={() => setColorMode("rgb")}>
                                            RGB
                                            <DropdownMenuShortcut>1</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => setColorMode("classification")}>
                                            Classification
                                            <DropdownMenuShortcut>2</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => setColorMode("intensity")}>
                                            Intensity
                                            <DropdownMenuShortcut>3</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => setColorMode("metaIndex")}>
                                            Source
                                            <DropdownMenuShortcut>4</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => setColorMode("date")}>
                                            Date
                                            <DropdownMenuShortcut>5</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <Button
                                size="sm"
                                className="ml-2"
                                variant="outline"
                                onClick={() => viewer.adjustPointSize(-1)}
                                title="Decrease point size"
                            >
                                <Dot className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.adjustPointSize(1)}
                                title="Increase point size"
                            >
                                <Circle className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => resetViewerState()}
                                title="Reset everything"
                                className="mx-2"
                            >
                                <ListRestart className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.resetCamera()}
                                title="Reset camera"
                            >
                                <SwitchCamera className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.toggleAnimation()}
                                title="Toggle animation"
                            >
                                <Play className="h-4" />
                            </Button>
                        </div>

                        <div className="absolute bottom-2 right-2 w-40 rounded-md bg-white/40 p-2 text-sm backdrop-blur-sm">
                            {responseInfo && (
                                <div className="flex flex-col items-stretch">
                                    <div className="font-bold text-gray-500">Response</div>

                                    <Info heading="Time">{humanDuration(responseInfo.elapsed)}</Info>
                                    <Info heading="Points">{(responseInfo.pointCount / 1_000_000).toFixed(3)} M</Info>
                                    <Info heading="Size">{humanSize(responseInfo.byteSize)}</Info>
                                    <Info heading="Area">
                                        {humanArea(new Polygon(responseInfo.queryPolygon).getArea())}
                                    </Info>

                                    <div className="my-2 flex flex-col gap-2">
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            className="w-full"
                                            onClick={() => onShareLink()}
                                        >
                                            <Share2 className="h-4" />
                                            {shareLinkText}
                                        </Button>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button size="sm" variant="outline" className="w-full">
                                                    <Download className="h-4" />
                                                    Export
                                                    <ChevronDown className="mt-0.5 h-4" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <DropdownMenuItem
                                                    onClick={() => {
                                                        if (viewer)
                                                            PointChunk.startFileDownload(
                                                                tableName,
                                                                state.queryPolygon,
                                                                state.maxPoints,
                                                                getMetaFilter(),
                                                            );
                                                    }}
                                                >
                                                    LAZ
                                                </DropdownMenuItem>
                                                <DropdownMenuItem disabled>LAS</DropdownMenuItem>
                                                <DropdownMenuItem disabled>e57</DropdownMenuItem>
                                                <DropdownMenuItem disabled>glTF</DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            )}
                        </div>

                        {responseInfo && responseInfo.seenIndices.length > 0 && (
                            <div className="absolute bottom-2 left-2 z-10 flex max-h-[calc(100%_-_1rem)] flex-col gap-1 !overflow-y-scroll rounded-sm bg-white/40 p-2 text-xs backdrop-blur-sm">
                                <div
                                    className="flex w-full cursor-pointer items-center justify-between gap-1 text-sm font-bold text-gray-500"
                                    onClick={() => {
                                        setFilesUsedCollapsed((old) => !old);
                                    }}
                                >
                                    <div>Files used: {responseInfo.seenIndices.length}</div>

                                    {filesUsedCollapsed ? (
                                        <ChevronUp className="h-5"></ChevronUp>
                                    ) : (
                                        <ChevronDown className="h-5"></ChevronDown>
                                    )}
                                </div>

                                {!filesUsedCollapsed && (
                                    <FilesByLicense
                                        fileMetadata={fileMetadata}
                                        responseInfo={responseInfo}
                                        viewer={viewer}
                                    />
                                )}
                            </div>
                        )}

                        <ViewerContainer />
                    </div>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
}
