import { DepthTexture, ShaderMaterial, Texture } from "three";
import edlFrag from "./shaders/edl.frag";

export class EDLMaterial extends ShaderMaterial {
    constructor(colorTexture: Texture, depthTexture: DepthTexture, camNear: number, camFar: number) {
        super({
            glslVersion: "300 es",
            uniforms: {
                uColorTexture: { value: colorTexture },
                uDepthTexture: { value: depthTexture },
                uCameraNear: { value: camNear },
                uCameraFar: { value: camFar },
                uResolution: { value: [100, 100] },
            },
            vertexShader: `
				out vec2 vUv;

				void main() {
					vUv = uv;

					mat4 modelToScreen = projectionMatrix * modelViewMatrix;

					gl_Position = modelToScreen * vec4(position, 1.0);
				}`,
            fragmentShader: edlFrag,
        });
    }

    setResolution(width: number, height: number) {
        this.uniforms.uResolution.value = [width, height];
    }
}
