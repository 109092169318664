import { Feature, Map } from "ol";
import { defaults as defaultControls } from "ol/control";
import { click, pointerMove } from "ol/events/condition";
import { FeatureLike } from "ol/Feature";
import { GeoJSON } from "ol/format";
import { Point } from "ol/geom";
import Polygon, { fromExtent as polygonFromExtent } from "ol/geom/Polygon";
import { defaults as defaultInteractions, Draw, Select } from "ol/interaction";
import { createBox, DrawEvent } from "ol/interaction/Draw";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import * as olProj from "ol/proj";
import { register as registerProj4 } from "ol/proj/proj4";
import { OSM, Tile, TileDebug, TileWMS, Vector as VectorSource, XYZ } from "ol/source";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import View from "ol/View";
import proj4 from "proj4";
import videoIcon from "../assets/video.svg";
import { FileMetadataItem, getRampedColor, humanSize } from "./utils";

proj4.defs(
    "EPSG:3879",
    "+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
);
proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

registerProj4(proj4);

export { polygonFromExtent };

const OFFLINE_MAP_ENABLED = localStorage.getItem("OFFLINE_MAP") === "false" ? false : true;

export type SelectedFiles = Array<{ original_filename: string; file_size: number; num_points: number }>;

export const BASEMAP_SOURCE = {
    osm: new OSM({}),
    googleOrto: new XYZ({ url: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" }),
    googleOrtoLabels: new XYZ({ url: "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" }),
    googleRoads: new XYZ({ url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}" }),
    googleTerrain: new XYZ({ url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}" }),
    kapsiOrto: new TileWMS({ url: "https://tiles.kartat.kapsi.fi/ortokuva", params: {} }),
    kapsiPerus: new TileWMS({ url: "https://tiles.kartat.kapsi.fi/peruskartta", params: {} }),
    kapsiTausta: new TileWMS({ url: "https://tiles.kartat.kapsi.fi/taustakartta", params: {} }),
};

export class LumiMap {
    map: Map;
    view: View;

    onPolygonUpdated: null | ((polygon: number[][][] | null) => void) = null;
    onSelectedFilesChanged: null | ((type: "click" | "hover", files: SelectedFiles) => void) = null;

    private draw: Draw | null;

    private baseMapLayer: TileLayer<Tile>;

    private boundsLayer: VectorLayer<Feature>;
    private boundsSource: VectorSource<Feature>;

    private newSelectionSource: VectorSource<Feature>;
    private currentSelectionSource: VectorSource<Feature>;

    private hoveredFeatures: Set<Feature>;
    private clickedFeatures: Set<Feature>;

    private selectPointerMove: Select;
    private selectPointerClick: Select;

    private cameraFeature: Feature<Point>;

    private activeTimeRange: number[] = [1900, 2100];

    private mapColorMode: "year" | "file" | "none" = "year";

    meta = new globalThis.Map<number, FileMetadataItem>();

    constructor() {
        this.baseMapLayer = new TileLayer({
            source: BASEMAP_SOURCE.osm,
        });

        this.boundsSource = new VectorSource<Feature>({ features: [] });

        this.boundsLayer = new VectorLayer({
            source: this.boundsSource,
            style: (feature) => {
                const meta_idx = feature.get("meta_index") ?? 0;
                const year = this.meta.get(meta_idx)?.year ?? 0;
                const inRange = year >= this.activeTimeRange[0] && year < this.activeTimeRange[1];

                let color = [0, 0, 0];
                if (this.mapColorMode === "file") {
                    color = getRampedColor(meta_idx ?? 0);
                } else if (this.mapColorMode === "year") {
                    color = getRampedColor(year);
                } else {
                    color = [128, 128, 255];
                }

                const useTightBounds = this.shouldUseTightBounds(feature);

                const color_text = color.join(",");

                return new Style({
                    stroke: new Stroke({
                        color: inRange
                            ? useTightBounds
                                ? `rgba(0, 0, 0, 0.60)`
                                : `rgba(0, 0, 0, 0.50)`
                            : "rgba(128,128,128,0.25)",
                        width: useTightBounds ? 1.5 : 1,
                    }),
                    fill: new Fill({
                        color: inRange
                            ? `rgba(${color_text}, ${useTightBounds ? "0.40" : "0.3"})`
                            : "rgba(128,128,128,0.15)",
                    }),
                    text: this.getTextStyle(feature),
                });
            },
        });

        this.newSelectionSource = new VectorSource<Feature>({ features: [] });
        this.currentSelectionSource = new VectorSource<Feature>({ features: [] });

        this.hoveredFeatures = new Set();
        this.clickedFeatures = new Set();

        this.view = new View({
            // center on Helsinki
            center: olProj.fromLonLat([24.937, 60.17]),
            zoom: 13,
        });

        this.cameraFeature = new Feature({ geometry: new Point(olProj.fromLonLat([24.937, 60.17])) });

        this.map = new Map({
            layers: [
                // Show tile boundaries and tile coordinates
                new TileLayer({ source: new TileDebug({}) }),

                // Offline base map
                ...(OFFLINE_MAP_ENABLED
                    ? [
                          new TileLayer({
                              source: new XYZ({
                                  url: "/api/test_data/maptiles/osm/{z}/{x}/{y}.png",
                                  maxZoom: 17,
                              }),
                          }),
                      ]
                    : []),

                // Base map
                this.baseMapLayer,

                // File boundaries
                this.boundsLayer,

                // Current selection
                new VectorLayer({
                    source: this.currentSelectionSource,
                    style: new Style({
                        stroke: new Stroke({ color: "rgba(34, 185, 34, 0.9)", width: 3 }),
                        fill: new Fill({ color: "rgba(0, 255, 0, 0.1)" }),
                    }),
                }),

                // New box selection
                new VectorLayer({
                    source: this.newSelectionSource,
                    style: new Style({
                        stroke: new Stroke({ color: "red", width: 3 }),
                        fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
                    }),
                }),

                // Camera position
                new VectorLayer({
                    source: new VectorSource<Feature>({
                        features: [this.cameraFeature],
                    }),
                    style: (f) =>
                        new Style({
                            image: new Icon({
                                anchor: [1.0, 0.5],
                                src: videoIcon,
                                rotation: -Math.PI / 2 - f.get("rotation"),
                            }),
                        }),
                }),
            ],
            view: this.view,
            controls: defaultControls({ zoom: false, rotate: false }),
            interactions: defaultInteractions({
                pinchRotate: false,
                altShiftDragRotate: false,
                zoomDuration: 100,
            }),
        });

        this.selectPointerMove = new Select({
            condition: pointerMove,
            layers: [this.boundsLayer],
            multi: true,
            style: (feat) =>
                new Style({
                    stroke: new Stroke({ color: "rgba(100, 150, 240, 0.8)", width: 2 }),
                    fill: new Fill({ color: "rgba(100, 150, 240, 0.20)" }),
                    text: this.getTextStyle(feat),
                }),
        });
        this.selectPointerClick = new Select({
            condition: click,
            layers: [this.boundsLayer],
            multi: true,
            style: (feat) =>
                new Style({
                    stroke: new Stroke({ color: "rgba(100, 150, 240, 0.8)", width: 3 }),
                    fill: new Fill({ color: "rgba(100, 150, 240, 0.30)" }),
                    text: this.getTextStyle(feat),
                }),
        });

        this.initializeInputHandling();

        this.draw = null;
    }

    setContainer(element: HTMLElement) {
        this.map.setTarget(element);
        element.addEventListener("pointerleave", () => {
            this.hoveredFeatures.clear();
            this.updateFileInfoList();
        });
    }

    shouldUseTightBounds(feature: FeatureLike) {
        // TODO: this should not be based on filename
        return /.*(drone|car)\/.*/.test(feature.get("original_filename"));
    }

    getTextStyle(feature: FeatureLike) {
        const zoom = this.view.getZoom() ?? 0;
        let text = "";
        const meta_index = feature.get("meta_index");
        const info = this.meta.get(meta_index);
        if (zoom >= 15) {
            text += feature.get("original_filename");
        }
        if (zoom >= 16) {
            if (info) {
                text += `\npoints: ${(info.num_points / 1_000_000).toFixed(2)} M`;
                text += `\nsize: ${humanSize(info.file_size)}`;
            }
        }
        return new Text({
            text: text,
            font: "14px sans-serif",
            fill: new Fill({ color: "black" }),
            stroke: new Stroke({ color: "white", width: 2 }),
        });
    }

    onDrawEnd(type: "box" | "polygon", e: DrawEvent) {
        const geom = e.feature.getGeometry();
        if (geom instanceof Polygon && this.onPolygonUpdated) {
            if (type === "box") {
                this.onPolygonUpdated(polygonFromExtent(geom.getExtent()).getCoordinates());
            } else if (type === "polygon") {
                this.onPolygonUpdated(geom.getCoordinates());
            }
        }

        if (this.draw) {
            const d = this.draw;
            this.draw = null;
            setTimeout(() => {
                // do not remove immediately to prevent the final double click from zooming in
                this.map.removeInteraction(d);
                this.selectPointerMove.setActive(true);
                this.selectPointerClick.setActive(true);
            }, 200);
        }

        this.onPolygonUpdated?.(null);
    }

    drawBox() {
        this.startDraw();
        this.draw = new Draw({
            type: "Circle",
            source: this.newSelectionSource,
            geometryFunction: createBox(),
        });

        this.draw.on("drawend", (e) => this.onDrawEnd("box", e));
        this.newSelectionSource.clear();
        this.map.addInteraction(this.draw);
    }

    drawPolygon() {
        this.startDraw();
        this.draw = new Draw({
            type: "Polygon",
            source: this.newSelectionSource,
        });
        this.draw.on("drawend", (e) => this.onDrawEnd("polygon", e));
        this.newSelectionSource.clear();
        this.map.addInteraction(this.draw);
    }

    startDraw() {
        this.cancelDraw();
        this.selectPointerClick.setActive(false);
        this.selectPointerMove.setActive(false);
    }

    cancelDraw() {
        if (this.draw) {
            console.log("cancelDraw");
            this.draw.abortDrawing();
            this.map.removeInteraction(this.draw);
            this.draw = null;
            this.selectPointerClick.setActive(true);
            this.selectPointerMove.setActive(true);
        }
    }

    async loadGeoJSON(url: string, projection: "EPSG:3879" | "EPSG:4326" | "EPSG:3857") {
        const gjs = await fetch(url).then((r) => r.json());
        const features = new GeoJSON({
            dataProjection: projection,
            featureProjection: "EPSG:3857",
        }).readFeatures(gjs);

        for (const feat of features) {
            if (this.shouldUseTightBounds(feat)) {
                this.boundsSource.addFeature(feat);
            }
        }

        this.view.fit(this.boundsSource.getExtent(), { padding: [50, 50, 50, 50] });

        return features;
    }

    async updateVisibleBounds(meta: globalThis.Map<number, FileMetadataItem>, projection: string) {
        this.meta = meta;
        this.boundsSource.clear();

        meta.forEach((fileInfo, i) => {
            const aabb_min = [fileInfo.transformed_aabb.min[0], fileInfo.transformed_aabb.min[1]];
            const aabb_max = [fileInfo.transformed_aabb.max[0], fileInfo.transformed_aabb.max[1]];
            const polygon = new Polygon([
                [
                    [aabb_min[0], aabb_min[1]],
                    [aabb_min[0], aabb_max[1]],
                    [aabb_max[0], aabb_max[1]],
                    [aabb_max[0], aabb_min[1]],
                    [aabb_min[0], aabb_min[1]],
                ],
            ]);
            if (projection) {
                polygon.transform(projection, "EPSG:3857");
            }
            const feat = new Feature({ geometry: polygon });
            feat.set("original_filename", fileInfo.original_filename);
            feat.set("meta_index", i);

            if (!this.shouldUseTightBounds(feat)) {
                this.boundsSource.addFeature(feat);
            }
        });

        this.view.fit(this.boundsSource.getExtent(), { padding: [50, 50, 50, 50] });
    }

    updateCurrentSelection(poly: number[][][]) {
        this.currentSelectionSource.clear();
        this.currentSelectionSource.addFeature(new Feature({ geometry: new Polygon(poly) }));

        this.newSelectionSource.clear();
    }

    zoom(dir: number) {
        this.view.setZoom((this.view.getZoom() ?? 0) + dir);
    }

    updateFileInfoList() {
        let selectedFeatures = this.clickedFeatures.size > 0 ? this.clickedFeatures : this.hoveredFeatures;

        this.onSelectedFilesChanged?.(
            selectedFeatures === this.clickedFeatures ? "click" : "hover",
            Array.from(selectedFeatures)
                .map((f) => {
                    const info = this.meta.get(f.get("meta_index"));
                    if (!info) return null;
                    return {
                        file_size: info.file_size,
                        original_filename: f.get("original_filename"),
                        num_points: info.num_points,
                    };
                })
                .filter((x): x is FileMetadataItem => !!x),
        );
    }

    initializeInputHandling() {
        this.map.addInteraction(this.selectPointerMove);
        this.map.addInteraction(this.selectPointerClick);

        this.selectPointerMove.on("select", (e) => {
            for (const sel of e.selected) {
                this.hoveredFeatures.add(sel);
            }
            for (const desel of e.deselected) {
                this.hoveredFeatures.delete(desel);
            }
            this.updateFileInfoList();
        });

        this.selectPointerClick.on("select", (e) => {
            for (const sel of e.selected) {
                this.clickedFeatures.add(sel);
            }
            for (const desel of e.deselected) {
                this.clickedFeatures.delete(desel);
            }
            this.updateFileInfoList();
        });
    }

    setBaseMap(name: keyof typeof BASEMAP_SOURCE) {
        this.baseMapLayer.setSource(BASEMAP_SOURCE[name]);
    }

    updateCameraLocation(x: number, y: number, rotation: number) {
        this.cameraFeature.set("rotation", rotation);
        this.cameraFeature.setGeometry(new Point([x, y]));
    }

    updateActiveYearRange(range: [number, number]) {
        this.activeTimeRange = [range[0], range[1]];
        this.boundsLayer.changed();
    }

    setColorMode(viewerColorMode: "classification" | "rgb" | "intensity" | "date" | "metaIndex") {
        if (viewerColorMode === "classification" || viewerColorMode === "rgb" || viewerColorMode === "intensity") {
            this.mapColorMode = "none";
        } else if (viewerColorMode === "date") {
            this.mapColorMode = "year";
        } else if (viewerColorMode === "metaIndex") {
            this.mapColorMode = "file";
        } else {
            console.error("unknown color mode", viewerColorMode);
        }
        console.log("set map color mode to:", this.mapColorMode, "viewer:", viewerColorMode);

        this.boundsLayer.changed();
    }
}
