import {
    BufferGeometry,
    Float32BufferAttribute,
    Int32BufferAttribute,
    Points,
    Uint16BufferAttribute,
    Uint8BufferAttribute,
} from "three";
import { CustomPointMaterial } from "./point-material";

export const DEFAULT_POINT_MATERIAL = new CustomPointMaterial();

type QuerySpatialParameters = {
    output_format?: "Laz" | "Binary";
    max_points: number | null;
    boundary: { Polygon: number[][][] } | { Aabb: number[] };
    meta_filter: number[] | null;
};

export class PointChunk {
    points: Points;
    pointCount: number;
    byteSize: number;
    seenIndices: Set<number>;

    constructor(points: Points, pointCount: number, byteSize: number, seenIndices: Set<number>) {
        this.points = points;
        this.pointCount = pointCount;
        this.byteSize = byteSize;
        this.seenIndices = seenIndices;
    }

    static async startFileDownload(
        tableName: string,
        queryPolygon: number[][][],
        maxPoints: number | null,
        meta_filter: number[] | null,
    ) {
        const params: QuerySpatialParameters = {
            output_format: "Laz",
            max_points: maxPoints,
            boundary: { Polygon: queryPolygon },
            meta_filter: meta_filter,
        };

        const resp = await fetch(`/api/tables/${tableName}/query_spatial`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
        });

        // Currently the query_spatial uses POST, so the download can't be started with a simple link
        // TODO: investigate if this starting the download this way has some performance impact
        const blob = await resp.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = resp.headers.get("Content-Disposition")?.split("filename=")[1].replaceAll('"', "") || "export.laz";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    static async loadFromAPI(
        tableName: string,
        queryPolygon: number[][][],
        maxPoints: number | null,
        meta_filter: number[] | null,
    ) {
        const start = performance.now();
        const params: QuerySpatialParameters = {
            max_points: maxPoints,
            boundary: { Polygon: queryPolygon },
            meta_filter: meta_filter,
        };

        const resp = await fetch(`/api/tables/${tableName}/query_spatial`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
        });
        console.log("fetched", maxPoints, queryPolygon, meta_filter, "in", performance.now() - start);

        if (!resp.ok) {
            throw new Error(`Failed to fetch points: HTTP ${resp.status} ${resp.statusText}`);
        }

        const buf = await resp.arrayBuffer();

        const dataView = new DataView(buf, 0, 8);
        const pointCountBigInt = dataView.getBigInt64(0, true);
        const pointCount = Number(pointCountBigInt);

        let bytes_read = 8;

        const positions = new Float32Array(buf, bytes_read, 3 * pointCount);
        bytes_read += 4 * 3 * pointCount;

        const meta_indices = new Uint32Array(buf, bytes_read, pointCount);
        bytes_read += 4 * pointCount;

        const seen_indices_len = new DataView(buf, bytes_read, 4).getUint32(0, true);
        bytes_read += 4;

        const seen_indices = new Uint32Array(buf, bytes_read, seen_indices_len);
        bytes_read += 4 * seen_indices_len;

        const classifications = new Uint8Array(buf, bytes_read, pointCount);
        bytes_read += pointCount;

        const colors = new Uint8Array(buf, bytes_read, 3 * pointCount);
        bytes_read += 3 * pointCount;

        const intensities = new Uint16Array(buf, bytes_read, pointCount);
        bytes_read += 2 * pointCount;

        const pointGeom = new BufferGeometry();
        pointGeom.setAttribute("position", new Float32BufferAttribute(positions, 3));
        pointGeom.setAttribute("meta_index", new Int32BufferAttribute(meta_indices, 1, false));
        pointGeom.setAttribute("classification", new Uint8BufferAttribute(classifications, 1, false));
        pointGeom.setAttribute("color", new Uint8BufferAttribute(colors, 3, true));
        pointGeom.setAttribute("intensity", new Uint16BufferAttribute(intensities, 1, true));

        const points = new Points(pointGeom, DEFAULT_POINT_MATERIAL);

        return new PointChunk(points, pointCount, buf.byteLength, new Set(seen_indices));
    }

    static createDummyData(pointCount: number) {
        const pointGeom = new BufferGeometry();

        const positions = new Float32Array(3 * pointCount);
        const colors = new Uint8Array(3 * pointCount);

        const rnd = (from: number, to: number) => from + (to - from) * Math.random();

        const size = 50.0;

        const classifications = new Uint8Array(pointCount);

        const intensities = new Uint16Array(pointCount);

        for (let i = 0; i < pointCount; i++) {
            const x = rnd(-size, size);
            const y = rnd(-size, size);
            const z = rnd(-0.5, 0.5);

            positions[3 * i] = x;
            positions[3 * i + 1] = y;
            positions[3 * i + 2] = z + 0.1 * Math.sin(x) + 0.3 * Math.sin(y * 0.5) + Math.cos((x * x + y * y) / size);

            colors[3 * i] = 128 + (x / size) * 128;
            colors[3 * i + 1] = 128 + (y / size) * 128;
            colors[3 * i + 2] = 255;

            classifications[i] = Math.random() < (x + size) / (size * 2) ? 2 : 4;
            intensities[i] = 65535 * Math.random();
        }

        pointGeom.setAttribute("position", new Float32BufferAttribute(positions, 3));
        pointGeom.setAttribute("color", new Uint8BufferAttribute(colors, 3, true));
        pointGeom.setAttribute("classification", new Int32BufferAttribute(classifications, 1));

        const points = new Points(pointGeom, DEFAULT_POINT_MATERIAL);

        return new PointChunk(points, pointCount, pointCount * (3 * 4 + 3), new Set());
    }
}
