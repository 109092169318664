#include <packing>

uniform sampler2D uColorTexture;
uniform sampler2D uDepthTexture;

uniform vec2 uResolution;

uniform float uCameraNear;
uniform float uCameraFar;

in vec2 vUv;

out vec4 FragColor;

float readDepth(vec2 coord) {
	float fragCoordZ = texture(uDepthTexture, coord).x;
	float viewZ = perspectiveDepthToViewZ(fragCoordZ, uCameraNear, uCameraFar);
	return viewZToOrthographicDepth(viewZ, uCameraNear, uCameraFar);
}

float toMeters(float depth) {
	return uCameraNear + depth * (uCameraFar - uCameraNear);
}

void main() {
	vec4 originalColor = texture(uColorTexture, vUv);
	float depth_raw = readDepth(vUv);

	float depth_m = toMeters(depth_raw);

    float dX = 1.0 / uResolution[0];
    float dY = 1.0 / uResolution[1];

	// sample depth buffer from 4 nearby pixels
    float depth_1 = toMeters(readDepth(vUv + vec2(dX, 0.0)));
    float depth_2 = toMeters(readDepth(vUv + vec2(-dX, 0.0)));
    float depth_3 = toMeters(readDepth(vUv + vec2(0.0, dY)));
    float depth_4 = toMeters(readDepth(vUv + vec2(0.0, -dY)));

	// TODO: EDL could be probably calculated better. See how it's done other EDL implementations.

	// sum the relative depth of each sampled direction, if it's closer than the current pixel
	float edl =
		max(0.0, depth_m / depth_1 - 1.0) +
		max(0.0, depth_m / depth_2 - 1.0) +
		max(0.0, depth_m / depth_3 - 1.0) +
		max(0.0, depth_m / depth_4 - 1.0);

	float EDL_STRENGTH = 30.0;

	if (edl > 0.0) {
		// if the pixel is "behind" sampled nearby points, darken it
		FragColor = mix(originalColor, vec4(0.0, 0.0, 0.0, 1.0), clamp(edl * EDL_STRENGTH, 0.0, 0.80));
	}
	else if (originalColor.a == 0.0) {
		// render nothing on top of transparent background
		discard;
	} else {
		// let normal "fully visible" pixels pass through unmodified
		FragColor = originalColor;
	}
}